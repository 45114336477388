import React from "react"

import ProductsGrid from "./ProductsGrid/ProductsGrid"

const Products = ({ children }) => (
  <div className="products">
    <div className="products__text">{children}</div>
    <ProductsGrid />
  </div>
)

export default Products
