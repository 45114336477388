import React from "react"
import Img from "gatsby-image"

import { Link } from "gatsby"

const Card = ({ image, headerText, linkTo }) => (
  <div className="card">
    <Link to={linkTo}>
      <div className="card__imagebox">
        <Img className="card__image" fluid={image || {}} alt={headerText} />
      </div>
      <div className="card__description">
        <h4 className="card__header">{headerText}</h4>
      </div>
    </Link>
  </div>
)

export default Card
