import React from "react"
import Card from "../../Card/Card"

import { graphql, useStaticQuery } from "gatsby"

const ProductsGrid = () => {
  const { cb, gphe, alfanova, minicity, drycooler } = useStaticQuery(
    graphql`
      query {
        cb: file(relativePath: { eq: "products/cb.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        gphe: file(relativePath: { eq: "products/gphe.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        alfanova: file(relativePath: { eq: "products/alfanova.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        minicity: file(relativePath: { eq: "products/minicity.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
        drycooler: file(relativePath: { eq: "products/drycooler.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <div className="products__grid" id="grid">
      <Card
        headerText="Wymienniki lutowane miedzią - seria CB"
        image={cb.childImageSharp.fluid}
        linkTo="/produkty/wymienniki-cb"
      />
      <Card
        headerText="Wymienniki ciepła skręcane uszczelkowe"
        image={gphe.childImageSharp.fluid}
        linkTo="/produkty/wymienniki-gphe"
      />
      <Card
        headerText="Wymienniki Alfa Nova ze&nbsp;stali kwasoodpornej"
        image={alfanova.childImageSharp.fluid}
        linkTo="/produkty/alfa-nova"
      />
      <Card
        headerText="Kompaktowe węzły cieplne Mini City Cetetherm"
        image={minicity.childImageSharp.fluid}
        linkTo="/produkty/minicity"
      />
      <Card
        headerText="Drycoolery LU-VE"
        image={drycooler.childImageSharp.fluid}
        linkTo="/produkty/drycoolery-lu-ve"
      />
      {/* <Card
        headerText="Wymienniki Cetetherm Cetecoil"
        image={alfanova}
        linkTo="/produkty/cetecoil"
      />
      <Card
        headerText="Zawory Naval"
        image={alfanova}
        linkTo="/produkty/zawory-naval"
      />
      <Card
        headerText="Układy stabilizacji ciśnienia"
        image={alfanova}
        linkTo="/produkty/uklady-stabilizacji-cisnienia"
      /> */}
    </div>
  )
}

export default ProductsGrid
